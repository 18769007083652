<template>
  <b-card>

    <b-row>

      <!-- Producto Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Prdducto Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            ref="previewEl"
            :src="data.item.imagen"
            :text="data.item.nombre == '' ? 'P' : avatarText(data.item.nombre)"
            size="90px"
            rounded
            variant="light-success"
            :title="data.item.nombre == '' ? 'P' : avatarText(data.item.nombre)"
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ data.item.nombre }}
              </h4>
              <span class="card-text">{{ data.item.codigo }}</span>
            </div>
            <div class="d-flex flex-wrap">
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BoxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Stock Actual</span>
            </th>
            <td class="pb-50 text-capitalize">
              <colStock
                :data="data"
              />
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Estado</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ data.item.estado }}
            </td>
          </tr>
          <tr v-if="data.item.unidad">
            <th class="pb-50">
              <feather-icon
                icon="ShuffleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Unidad</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ data.item.unidad }}
            </td>
          </tr>
          <tr v-if="data.item.nombreCategoria">
            <th class="pb-50">
              <feather-icon
                icon="TagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Categoría</span>
            </th>
            <td class="pb-50">
              {{ data.item.nombreCategoria }}
            </td>
          </tr>
          <tr v-if="data.item.nombreUbicacion">
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Ubicación</span>
            </th>
            <td>
              {{ data.item.nombreUbicacion }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

const colStock = () => import('@/layouts/components/Recycled/List/colStock.vue')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    colStock,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
